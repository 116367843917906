import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./InitiateOfferTable.scss";
import { InitiateOfferModel } from "./Model";
import DataTable from "react-data-table-component";
import { getAPI, postAPI, deleteAPI } from "../../../services/CepApiServices";
import { TableColumn } from "react-data-table-component";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import { McButton, McIcon, McNotification } from "@maersk-global/mds-react-wrapper";
import { getUserRole } from "../../../utils/token";
import { StatusList } from "../../../utils/StatusList";
import WriteAccessModal from "../../../IndicationRequest/IndicationRequestPages/IndicationRequestTable/WriteAccessModal";
import ManageWriteAccessRequestsModal from "../../../CharterRequest/RequestPages/ManageWriteAccessRequestsModal/ManageWriteAccessRequestsModal";
import { toast } from "react-toastify";
import WriteAccessNewModal from "../../../IndicationRequest/IndicationRequestPages/IndicationRequestTable/WriteAccessNewModal";
import RequestWriteAccessModal from "../../../CharterRequest/RequestPages/RequestWriteAccessModal/RequestWriteAccessModal";
import ManageAccessModal from "../../../CharterRequest/RequestPages/ManageAccessModal/ManageAccessModal";
import AssignRepresentativeModal from "../../../CharterRequest/RequestPages/AssignRepresentativeModal/AssignRepresentativeModal";
import EditVORDetailsModal from "../../../CharterRequest/RequestPages/EditVORDetailsModal/EditVORDetailsModal";
import RemoveRepresentativeModal from "../../../CharterRequest/RequestPages/RemoveRepresentativeModal/RemoveRepresentativeModal";

interface IPROPS {
  userRole?: any;
}

const InitiateOfferTable = ({ userRole }: IPROPS) => {
  const [tableData, setTableData] = useState([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [isWriteAccess, SetIsWriteAccess] = useState(false);
  const [rowData, setRowData] = useState<any>();
  const [showNotificationVO, setShowNotificationVO] = useState(false);
  const [manageWriteRequestModal, setManageWriteRequestModal] = useState(false);
  const [accessRequestsData, setAccessRequestsData] = useState<any>();
  const [brokerDetailsData, setBrokerDetailsData] = useState<any>({});
  const [VOREditDetails, setVOREditDetails] = useState<any>();
  const [VORAssignmentDetails, setVORAssignmentDetails] = useState<any>();
  const [assignmentId, setAssignmentId] = useState(0);
  const [individualAccessRequestsData, setIndividualAccessRequestsData] = useState<any>();
  const [isRequestWriteAccessModal, setIsRequestWriteAccessModal] = useState(false);
  const [disableRequestWriteAccessBtn, setDisableRequestWriteAccessBtn] = useState(false);
  const [isWriteAccessVOR, setIsWriteAcessVOR] = useState(false);
  const [userRoleVO, setUserRole] = useState<any>();
  const [charterRequestId, setCharterRequestId] = useState(0);
  const [indicationRequestId, setIndicationRequestId] = useState(0);
const [idVal,setIDVal] = useState(0);
const [vorDetailsModal, setIsVORDetailsModal] = useState(false);
const [rowAccessType, setRowAccessType] = useState<any>();
const [requestWriteAccessSuccess, setRequestWriteAccessSuccess] = useState(false);
const [deleteRepId, setDeleteRepId] = useState(0);
const [deleteVORModal,setIsDeleteVORModal] = useState(false);

  const history = useNavigate();
  const getInitiateOfferData = async () => {
    setIsTableLoader(true);
    await getAPI(`InitialOffer/GetSentOffers`).then((response) => {
      setTableData(response);
    });
    setIsTableLoader(false);
  };
  useEffect(() => {
    const getUserRoleAsync = async () => {
      const userRole = await getUserRole();
      setUserRole(userRole);
      if (userRole === "VesselOwner") {
        getInitiateOfferData();
      } else if (userRole === "OwnerRepresentative") {
        getInitiateOfferData();
        getNotificationForVOR(userRole);
      }
    };
    getUserRoleAsync();
  }, []);
  const accessTypeSetter = (accessType: any) => {
    if (accessType == "Read") {
      return (
        <span className="read-access">
          <McIcon icon="eye" className="read-access-icon"></McIcon>
          {accessType}
        </span>
      );
    } else if (accessType == "Write") {
      return (
        <span className="write-access">
          <McIcon icon="pencil" className="write-access-icon"></McIcon>
          {accessType}
        </span>
      );
    }
  };
  const handleInitiateOffer = (row: any) => {
    history(`/InitiateOfferDetails?id=${row.indicationRequestId}`);
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const pageReload = () => {
    getInitiateOfferData();
  };
  const closeFunc = () => {
    SetIsWriteAccess(false);
  };
  const closeFuncVOR = () => {
    setIsWriteAcessVOR(false);
  };
  const handleWriteAssigneeFun = (value: any) => {
    setRowData(value);
    fetchBrokerDetails(value);
    setCharterRequestId(value.charterRequestId);
    setIndicationRequestId(value?.indicationRequestId);
    setRowAccessType(value?.accessType);
    // setIDVal(value.id);
    if (userRole == "OwnerRepresentative") {
      getAccessRequestsData(value?.indicationRequestId);
    }
    setTimeout(() => {
      if (userRoleVO == "VesselOwner") {
        if(value?.vesselOwnerRepresentative == null) {
          // Add modal here
          SetIsWriteAccess(true);
        } else {
          // Edit modal here with add more (based on read or write)
          if(value?.accessType == 'Write') {
            // Edit modal with +add more
            setIsVORDetailsModal(true);
          } else {
            // Edit modal with Request Write Access button in read only mode
            setIsVORDetailsModal(true);
          }
        }      
      } else if (userRoleVO == "OwnerRepresentative") {
        if(value?.accessType == 'Write') {
          // setIsWriteAcessVOR(true);
          setIsVORDetailsModal(true);
        } else {
          setIsVORDetailsModal(true);
        }
      }
      // SetIsWriteAccess(true);
    }, 1800);
  };
  const openWriteAccessRequests = () => {
    setManageWriteRequestModal(true);
  }

  const onWriteAccessRequestsModalClose = () => {
    setManageWriteRequestModal(false);
  }

  const getNotificationForVOR = async (role) => {
    await getAPI(`IndicationRequest/GetVORWriteAccessRequests`).then(
      (response) => {
        if (response.length >= 1) {
          role === "OwnerRepresentative" ? setShowNotificationVO(true) : setShowNotificationVO(false);
          setAccessRequestsData(response);
        }
        else {
          setShowNotificationVO(false);
        }

      }
    );
  }
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  const setGiveAccessDataVO = async (obj: any): Promise<void> => {
    const payload = obj;
    const status = await postAPI(`IndicationRequest/AssignWriteAccessToVO`, payload);
    if (status.StatusCode == 400 || status.message) {
      setTimeout(() => {
        notify("Failed to change access", false)
      }, 1000);
      setManageWriteRequestModal(false);
    }
    else {
      setTimeout(() => {
        notify("Access changed successfully.", true)
      }, 1000);
      setManageWriteRequestModal(false);
      getInitiateOfferData();
      getNotificationForVOR("OwnerRepresentative");
    }
  }

  const fetchBrokerDetails = async (data) => {
    try {
      let response: any;
      response = await getAPI(
        `IndicationRequest/GetVORAssignment?IndicationId=` +
        data.indicationRequestId
      );
      setVOREditDetails(response);
      setVORAssignmentDetails(response?.vOR_AssignmentUserDtos);
      setAssignmentId(response?.id);
      // checkReadOnlyRolesVO(response?.vOR_AssignmentUserDtos);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAccessRequestsData = async (id) => {
    await getAPI(`IndicationRequest/GetVORWriteAccessRequests?indication_id=${id}`).then(
      (response) => {
        if (response) {
          setIndividualAccessRequestsData(response);
        }
        else {
          //   setShowNotification(false);
        }

      }
    );
  }

  const getRequestWriteAccess = async () => {
    const response = await getAPI(
      `IndicationRequest/RequestVORWriteAccess?VORAssignmentId=` + assignmentId
    );
    if (response) {
      // SetIsWriteAccess(false);
      setIsRequestWriteAccessModal(false);
      setDisableRequestWriteAccessBtn(true);
    }
  }

  const requestWriteAccess = () => {
    setIsRequestWriteAccessModal(true);
  }

  const closeRequestWriteAccessModal = (type: any) => {
    if (type == "submit") {
      getRequestWriteAccess();
    }
  }

  const setManageAccessEditedDetails = async (obj: any): Promise<void> => {
    const payload = obj;
    const status = await postAPI(`IndicationRequest/SaveVORssignment`, payload);
    if (status.StatusCode == 400 || status.message) {
      setTimeout(() => {
        notify("Failed to edit representative", false)
      }, 1000);
      setIsWriteAcessVOR(false);
    }
    else {
      setTimeout(() => {
        notify("Representatives edited successfully.", true)
      }, 1000);
      setIsWriteAcessVOR(false);
      getInitiateOfferData();
      // setIsSendIndicationBtn(true);
      // setIsEditRepresentativeBtn(false);
      // setIsEditRepresentativeModal(false);
      // setCRDetailsAPI(crDetailsAPI + 1);
      // setIsCheckedTable(true);
    }
  }
    const assignCMRtoVsl = async (obj: any,type:any): Promise<void> => {
      const payload = obj;
      const status = await postAPI(`IndicationRequest/SaveVORssignment`, payload);
      if (status.StatusCode == 400 || status.message) {
        setTimeout(() => {
          notify("Failed to add representative", false)
        }, 1000);
        SetIsWriteAccess(false);
      }
      else {
        setTimeout(() => {
          notify(`Representatives ${type} successfully.`, true)
        }, 1000);
        SetIsWriteAccess(false);
        getInitiateOfferData();
      }
    };
  const addRepresentatives = (formatteddata: any) => {
    assignCMRtoVsl(formatteddata,'assigned');
  }

  const setEditedVORDetails = (data) => {
    assignCMRtoVsl(data,'edited');
  }
  const setDeletedRepresentativeData = (repData: any) => {
    setDeleteRepId(repData);
    setIsVORDetailsModal(false);
    setIsDeleteVORModal(true);
  }

    const deletedRepresentative = async (id: any): Promise<void> => {
      // const payload = obj;
      const status = await deleteAPI(`IndicationRequest/DeleteVORAssignment?Id=` + id);
      if (status?.StatusCode == 400 || status?.message) {
        setTimeout(() => {
          notify("Failed to delete representative", false)
        }, 1000);
        setIsDeleteVORModal(false);
        setIsVORDetailsModal(false);
      }
      else {
        setTimeout(() => {
          notify("Representatives deleted successfully.", true)
        }, 1000);
        setIsDeleteVORModal(false);
        // fetchBrokerDetails(rowDetails);
        setTimeout(() => {
          setIsVORDetailsModal(true);
        }, 1500);
      }
    };
    const onSubmitDelete = (id: any) => {
      deletedRepresentative(id);
    }
  const handleAssignee = (row: any) => {
    if (
      row?.statusId != 4 &&
      row?.statusId != 3 &&
      row?.statusId != 7 
      // && row.accessType == "Read"
    ) {
      return (
        <span
          className="manage-access"
          data-testid="manage-access"
          onClick={() => handleWriteAssigneeFun(row)}
        >
          Manage Access
        </span>
      );
    } else {
      return "--";
    }
  };
  const columns: TableColumn<InitiateOfferModel>[] = [
    {
      name: "Status",
      selector: (row) => (row.statusName ? row.statusName : ""),
      cell: (row) => StatusList(row.statusId, row.statusName),
      sortable: true,
      width: "9%",
    },
    {
      name: "Request Name",
      selector: (row) =>
        row.requestName !== null ? row.requestName.toLowerCase() : "",
      cell: (row) => (row.requestName !== null ? row.requestName : ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "Vessel Name",
      selector: (row) => (row.vesselName ? row.vesselName.toLowerCase() : ""),
      cell: (row) => (row.vesselName ? row.vesselName : ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "IMO",
      selector: (row) => (row.imo ? row.imo : ""),
      sortable: true,
      width: "7%",
    },
    {
      name: "Chartering Manager",
      selector: (row) =>
        row.charteringManager ? row.charteringManager.toLowerCase() : "",
      cell: (row) => (row.charteringManager ? row.charteringManager : ""),
      sortable: true,
      width: "12%",
    },
    {
      name: "CM Representative",
      selector: (row) =>
        row.charteringManagerRepresentative ? row.charteringManagerRepresentative.toLowerCase() : "",
      cell: (row) => (row.charteringManagerRepresentative ? row.charteringManagerRepresentative : ""),
      sortable: true,
      width: "11%",
    },
    {
      name: "Vessel Owner",
      selector: (row) =>
        row.vesselOwner ? row.vesselOwner.toLowerCase() : "",
      cell: (row) => (row.vesselOwner ? row.vesselOwner : ""),
      sortable: true,
      width: "11%",
    },
    {
      name: "VO Representative",
      selector: (row) =>
        row.vesselOwnerRepresentative ? row.vesselOwnerRepresentative.toLowerCase() : "",
      cell: (row) => (row.vesselOwnerRepresentative ? row.vesselOwnerRepresentative : ""),
      sortable: true,
      width: "11%",
    },
    // {
    //   name: "Access Type",
    //   selector: (row) => (row.accessType ? row.accessType : ""),
    //   cell: (row) => accessTypeSetter(row.accessType),
    //   sortable: true,
    //   width: "11%",
    // },
    {
      name: "Access",
      cell: (row) => handleAssignee(row),
      width: "7%",
    },
    {
      name: "Offer Type",
      selector: (row) => (row.offerType ? row.offerType.toLowerCase() : ""),
      cell: (row) => (row.offerType ? row.offerType : ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "",
      cell: (row) => (
        <McButton
          data-testid="view-button"
          label="View"
          onclick={(e) => handleInitiateOffer(row)}
          fit="small"
        ></McButton>
      ),
    },
  ];
  return (
    <>
      {showNotificationVO && (<div className="representative-notification">
        <McNotification heading="Write access requested"
          body="Check the requests and manage access" onClick={openWriteAccessRequests}></McNotification>
      </div>)}
      <div className="initialOfferPage">
        <div className="ir-table-main table-margin2">
          <DataTable
            columns={columns}
            data={tableData}
            progressPending={isTableLoader}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
          />
          {/* <WriteAccessModal
          isModalOpen={isWriteAccess}
          onCloseFunc={closeFunc}
          rowData={rowData}
          isCm={false}
          reloadPage={pageReload}
        /> */}

          {/* VO side, read-only */}
          {/* <WriteAccessNewModal isModalOpen={isWriteAccess} onCloseFunc={closeFunc} infoData={brokerDetailsData}
            individualAccessRequestsData={individualAccessRequestsData}
            requestWriteAccessModal={requestWriteAccess}
            disableRequestWriteAccessBtn={disableRequestWriteAccessBtn} />
          {/* VOR side, editable */}
          <ManageAccessModal isModalOpen={isWriteAccessVOR} onCloseFunc={closeFuncVOR} infoData={VOREditDetails}
            assignmentDetails={VORAssignmentDetails}
            individualAccessRequestsData={individualAccessRequestsData}
            requestWriteAccessModal={requestWriteAccess}
            userRole={"OwnerRepresentative"}
            indicationRequestId={indicationRequestId}
            setManageAccessEditedDetails={setManageAccessEditedDetails}
          />

          <ManageWriteAccessRequestsModal isModalOpen={manageWriteRequestModal}
            onModalClose={onWriteAccessRequestsModalClose}
            accessRequestsData={accessRequestsData}
            userRole={userRole}
            setGiveAccessData={setGiveAccessDataVO}
          />
          {/* /* Added */ }
          <AssignRepresentativeModal
        isModalOpen={isWriteAccess}
        infoData={addRepresentatives}
        onLoadData={rowData}
        crId={indicationRequestId}
        isCloseModal={() => SetIsWriteAccess(false)}
        isFromIndicationReq = {true}
        userRole={userRoleVO}
        negoVOSide={true}
      ></AssignRepresentativeModal>
      {vorDetailsModal && <EditVORDetailsModal
        isModalOpen={vorDetailsModal}
        infoData={VOREditDetails}
        isCloseModal={() => setIsVORDetailsModal(false)}
        setEditedVORDetails={setEditedVORDetails}
        deletedRepresentative={setDeletedRepresentativeData}
        crId={indicationRequestId}
        userRole={userRole}
        isReadOnlyUser={false}
        requestWriteAccessModal={requestWriteAccess}
        requestWriteAccessSuccess={requestWriteAccessSuccess}
        rowAccessType={rowAccessType}
      ></EditVORDetailsModal>} 
       {deleteVORModal && (<RemoveRepresentativeModal
        onDeleteOpen={deleteVORModal}
        deleteRepId={deleteRepId}
        onSubmit={onSubmitDelete}
      ></RemoveRepresentativeModal>)} 

          {/* /* Added */ }
          {/*Request Write Access confirmation modal */}
          {isRequestWriteAccessModal && (<RequestWriteAccessModal
            onModalOpen={isRequestWriteAccessModal}
            onModalClose={closeRequestWriteAccessModal}
          />)}

        </div>
      </div>
    </>
  );
};

export default InitiateOfferTable;
