import { useState,useEffect } from "react";
import {
  McModal,
  McButton,
  McTextarea,
  McIcon,
  McSelect,
} from "@maersk-global/mds-react-wrapper";
import { useForm, Controller } from "react-hook-form";
interface IPROPS {
  isModalOpen?: boolean;
  isCloseModal?: any;
  infoData?: any;
  onLoadData?: any;
  crId?: any;
  isFromIndicationReq?:any;
  userRole?: any;
  isFromCRDetails?:any;
  negoVOSide?:any;
}
const AssignRepresentativeModal = ({
  isModalOpen,
  isCloseModal,
  infoData,
  onLoadData,
  crId,
  isFromIndicationReq,
  userRole,
  isFromCRDetails,
  negoVOSide,
}: IPROPS) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();
  const [representatives, setRepresentatives] = useState([{ id: 1 }]);
  const [submitError, setSubmitError] = useState(false);
  const [submitWriteError, setSubmitWriteError] = useState(false);
  let accessType = [
    { value: "Write", label: "Write" },
    { value: "Read", label: "Read" },
  ];
  const addMoreFunc = () => {
    if (representatives.length >= 5) {
      return;
    }
    setRepresentatives([
      ...representatives,
      { id: representatives.length + 1 },
    ]);
  };
  const deleteRowFunc = (id: number) => {
    setRepresentatives(representatives.filter((rep) => rep.id !== id));
  };
  const onSubmit = (data) => {
    setSubmitError(false);
    setSubmitWriteError(false);
    const accessTypesArray = representatives.map(
      (_, index) => data[`accessType${index}`][0]?.value
    );

    if (accessTypesArray.filter((type) => type === "Write").length > 1) {
      setSubmitError(true);
      return;
    }
    //  else if (
    //   accessTypesArray.filter((type) => type === "Write").length == 0
    // ) {
    //   setSubmitWriteError(true);
    //   return;
    // }
    //VO assigning VOR
    if(isFromIndicationReq) {
      if(userRole == 'VesselOwner'){
        const formattedData = {
          id:0,
          indicationId: negoVOSide ? onLoadData?.indicationRequestId: onLoadData?.id,
          comment: data.comments,
          vOR_AssignmentUserDtos: representatives.map((_, index) => ({
            vorAssignmentUserId: 0,
            name: data[`name${index}`],
            email: data[`email${index}`],
            accessType: data[`accessType${index}`][0]?.value,
          })),    
        }
        infoData(formattedData);
      } else {
        const formattedData = {
          charteringRequestId: Number(crId),
          assignmentId: 0,
          vesselIMO: onLoadData[0]?.imo,
          openRange: onLoadData[0]?.openRange,
          openPort: onLoadData[0]?.openPort,
          cmrAssignmentUserDetails: representatives.map((_, index) => ({
            cmrAssignmentUserId: 0,
            name: data[`name${index}`],
            email: data[`email${index}`],
            accessType: data[`accessType${index}`][0]?.value,
          })),
          comment: data.comments,
        };
        infoData(formattedData);
      }
   
    }else {
      if(isFromCRDetails) {
        const formattedData = {
          charteringRequestId: Number(crId),
          assignmentId: 0,
          vesselIMO: onLoadData[0]?.imo ? onLoadData[0]?.imo : null,
          openRange: onLoadData[0]?.openRange ? onLoadData[0]?.openRange : null,
          openPort: onLoadData[0]?.openPort ? onLoadData[0]?.openPort: null,
          cmrAssignmentUserDetails: representatives.map((_, index) => ({
            cmrAssignmentUserId: 0,
            name: data[`name${index}`],
            email: data[`email${index}`],
            accessType: data[`accessType${index}`][0]?.value,
          })),
          comment: data.comments,
        };
        infoData(formattedData);
      } else {
              //CM assigning CMR
    const formattedData = {
      charteringRequestId: Number(crId),
      assignmentId: 0,
      vesselIMO: onLoadData?.imo ? onLoadData?.imo : null,
      openRange: onLoadData[0]?.openRange ? onLoadData[0]?.openRange : null,
      openPort: onLoadData[0]?.openPort ? onLoadData[0]?.openPort: null,
      cmrAssignmentUserDetails: representatives.map((_, index) => ({
        cmrAssignmentUserId: 0,
        name: data[`name${index}`],
        email: data[`email${index}`],
        accessType: data[`accessType${index}`][0]?.value,
      })),
      comment: data.comments,
    };
    infoData(formattedData);
      }

  } 
  };

  const onError = (errors, e) => console.log(errors, e);
  return (
    <>
      <form
        data-testid="form"
        className="charterMarketAddModal"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <McModal
          height="500px"
          width="900px"
          className="broker-info-modal"
          heading={`Assign Representative`}
          open={isModalOpen}
          backdropcloseactiondisabled
        >
          {representatives.length > 1 ? null : (
            <div
              style={{ marginBottom: "20px", fontSize: "16px", color: "#333" }}
            >
              There is no representative assigned to this vessel yet. Add one
              using the fields below.
            </div>
          )}

          <div>
            {submitError && (
              <span
                style={{
                  color: "red",
                  marginLeft: "10px",
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
              >
                * Only one "Write" access is allowed
              </span>
            )}
            {/* {submitWriteError && (
              <span
                style={{
                  color: "red",
                  marginLeft: "10px",
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
              >
                * Atleast one "Write" access is required
              </span>
            )} */}
          </div>
          <div
            className="closeIcon-modal"
            data-testid="close"
            onClick={isCloseModal}
          >
            <McIcon icon="times"></McIcon>
          </div>
          <div className="broker-data">
            {representatives.map((rep, index) => (
              <div key={rep.id} className="broker-data-io details-section">
                <div className="row">
                  <div className="col-md-4">
                    <div className="header space-btw">Name</div>
                    <div className="value">
                      <Controller
                        name={`name${index}`}
                        control={control}
                        defaultValue=""
                        data-testid={`name${index}`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <input
                            className="inputText"
                            placeholder="Name"
                            {...register(`name${index}`, { required: true })}
                            type="text"
                          />
                        )}
                      />
                      {errors[`name${index}`] && (
                        <span className="error-message">Name is required</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="header">
                      E-mail<span className="asterisk">*</span>
                    </div>
                    <div className="value">
                      <Controller
                        name={`email${index}`}
                        control={control}
                        defaultValue=""
                        data-testid={`email${index}`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <input
                            className="inputText"
                            placeholder="E-mail"
                            {...register(`email${index}`, { required: true })}
                            type="text"
                          />
                        )}
                      />
                      {errors[`email${index}`] && (
                        <span className="error-message">
                          E-mail is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="header">
                      Access type<span className="asterisk">*</span>
                    </div>
                    <div className="value">
                      <McSelect
                        {...register(`accessType${index}`, { required: true })}
                        name={`accessType${index}`}
                        placeholder="Select"
                        fit="small"
                        label=""
                        options={accessType.map((option) => ({
                          ...option,
                        }))}
                        data-testid={`accessType${index}`}
                      ></McSelect>
                      {errors[`accessType${index}`] && (
                        <span className="error-message">
                          Access type is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="value thrash-icon">
                      <McIcon
                        data-testid="delete-option"
                        icon="trash"
                        color="#42b0d5"
                        onClick={() => deleteRowFunc(rep.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {representatives.length < 5 && (
              <span className="add-more" onClick={addMoreFunc}>
                + Add more
              </span>
            )}
            <div className="broker-data-io details-section">
              <div className="value">
                <McTextarea
                  {...register("comments")}
                  name="comments"
                  label="Comments"
                  placeholder="Comment"
                  rows={3}
                  fit="medium"
                  data-testid="comments"
                />
              </div>
            </div>
          </div>
          <McButton
            slot="secondaryAction"
            variant="secondary"
            dialogAction="cancel"
            onClick={isCloseModal}
            role="close"
            fit="small"
          >
            Close
          </McButton>
          <McButton
            slot="primaryAction"
            variant="primary"
            type="submit"
            label="Confirm"
            data-testid="confirm"
            fit="small"
          />
        </McModal>
      </form>
    </>
  );
};
export default AssignRepresentativeModal;
