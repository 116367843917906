import { useState } from "react";
import { McModal, McButton, McIcon, McCheckbox, McNotification } from "@maersk-global/mds-react-wrapper";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
interface IPROPS {
    isModalOpen: any;
    onModalClose: any;
    accessRequestsData: any;
    userRole: any;
    setGiveAccessData: any;
}

const ManageWriteAccessRequestsModal = ({ isModalOpen, onModalClose, accessRequestsData, userRole, setGiveAccessData }: IPROPS) => {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm();
    const [enableNegotiation, setEnableNegotiation] = useState(false);
    const onError = (errors, e) => console.log(errors, e);
    const chkValueChanged = (e: any, label: string) => { };
    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: 42,
            minHeight: 32,
            width: 120,
            minWidth: 120,
        }),
    };
    const accessType = [
        { value: "Write", label: "Write" },
        { value: "Read", label: "Read" },
    ];
    const onSubmit = (data) => {
        const assignmentIds = accessRequestsData
            ?.filter((request, index) =>data[`giveAccess${index}`] == true)
            .map(request => request.assignmentId);
        setGiveAccessData(assignmentIds);
    }
    return (
        <>
            <form
                data-testid="form"
                className="charterMarketAddModal"
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <McModal
                    height="500px"
                    width="900px"
                    className="broker-info-modal"
                    heading={`Manage access`}
                    open={isModalOpen}
                    backdropcloseactiondisabled
                >
                    <div
                        className="closeIcon-modal"
                        data-testid="close"
                    >
                        <McIcon icon="times"></McIcon>
                    </div>
                    <br />
                    <div>
                        <McNotification heading="" color="yellow" appearance="warning"
                            body="Once you grant write access to the assignee representative, You will only have Read access.">
                        </McNotification>
                    </div>

                    <div className="broker-data">
                        <div className="broker-data-io details-section">
                            {accessRequestsData?.map((data: any, index: any) => {
                                return (
                                    <>
                                        <div key={index} className="row details-row">
                                            <div className="col-md-3">
                                                <div className="header space-btw">Vessel</div>
                                                <div className="value">
                                                    <Controller
                                                        name={`vessel${index}`}
                                                        control={control}
                                                        data-testid="vessel"
                                                        render={({ field: { onChange } }) => (
                                                            <input
                                                                className="inputTextNew"
                                                                {...register(`vessel${index}`)}
                                                                type="text"
                                                                role="vessel"
                                                                onChange={(val) => {
                                                                    chkValueChanged(val, `vessel${index}`);
                                                                    onChange(val.target.value);
                                                                }}
                                                                defaultValue={data?.vesselName}
                                                                readOnly
                                                            ></input>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="header space-btw">Name</div>
                                                <div className="value">
                                                    <Controller
                                                        name={`name${index}`}
                                                        control={control}
                                                        data-testid="name"
                                                        render={({ field: { onChange } }) => (
                                                            <input
                                                                className="inputTextNew"
                                                                {...register(`name${index}`)}
                                                                type="text"
                                                                role="name"
                                                                onChange={(val) => {
                                                                    chkValueChanged(val, `name${index}`);
                                                                    onChange(val.target.value);
                                                                }}
                                                                defaultValue={data?.name}
                                                                readOnly={
                                                                    (data?.accessType == "Write" && userRole === "CharteringManager") ? true : false
                                                                }
                                                            ></input>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="header">E-mail</div>
                                                <div className="value">
                                                    <Controller
                                                        name={`email${index}`}
                                                        control={control}
                                                        data-testid="email"
                                                        render={({ field: { onChange } }) => (
                                                            <input
                                                                className="inputTextNewLong"
                                                                {...register(`email${index}`)}
                                                                type="text"
                                                                role="email"
                                                                onChange={(val) => {
                                                                    chkValueChanged(val, `email${index}`);
                                                                    onChange(val.target.value);
                                                                }}
                                                                defaultValue={data.createdBy}
                                                            // readOnly={
                                                            //     (data?.accessType == "Write" && userRole === "CharteringManager") ? true : false
                                                            // }
                                                            ></input>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="header">Access type</div>
                                                <div className="value">
                                                    <Controller
                                                        control={control}
                                                        name={`accessType${index}`}
                                                        data-testid="accessType"
                                                        render={({ field: { onChange } }) => (
                                                            <Select
                                                                styles={customStyles}
                                                                defaultValue={{
                                                                    value: "Write",
                                                                    label: "Write",
                                                                }}
                                                                options={accessType}
                                                                name={`accessType${index}`}
                                                                onChange={(val: any) => onChange(val.value)}
                                                                isDisabled
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        
                                        <div key={index} className="row details-row">
                                            <div className="col-md-3">
                                                <div className="header space-btw">Open Port</div>
                                                <div className="value">
                                                    <Controller
                                                        name={`openPort${index}`}
                                                        control={control}
                                                        data-testid="openPort"
                                                        render={({ field: { onChange } }) => (
                                                            <input
                                                                className="inputTextNew"
                                                                {...register(`openPort${index}`)}
                                                                type="text"
                                                                role="openPort"
                                                                onChange={(val) => {
                                                                    chkValueChanged(val, `openPort${index}`);
                                                                    onChange(val.target.value);
                                                                }}
                                                                defaultValue={data?.openPort}
                                                                readOnly
                                                            ></input>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="header space-btw">Open Range</div>
                                                <div className="value">
                                                    <Controller
                                                        name={`openRange${index}`}
                                                        control={control}
                                                        data-testid="openRange"
                                                        render={({ field: { onChange } }) => (
                                                            <input
                                                                className="inputTextNew"
                                                                {...register(`openRange${index}`)}
                                                                type="text"
                                                                role="openRange"
                                                                onChange={(val) => {
                                                                    chkValueChanged(val, `openRange${index}`);
                                                                    onChange(val.target.value);
                                                                }}
                                                                defaultValue={data?.openRange}
                                                                readOnly
                                                            ></input>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 give-access-sec">
                                                <Controller
                                                    name={`giveAccess${index}`}
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field: { onChange } }) => (
                                                        <McCheckbox
                                                        className="inputTextNew"
                                                            name={`giveAccess${index}`}
                                                            label="Give access"
                                                            change={(e: any) => {
                                                                onChange(e?.target?.checked);
                                                            }}
                                                            data-testid="giveAccess"
                                                        ></McCheckbox>
                                                    )}
                                                />
                                            </div>

                                        </div>
                                        <div className="row-divider"></div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                    <McButton
                        slot="secondaryAction"
                        variant="secondary"
                        dialogAction="cancel"
                        role="cancel-cp"
                        fit="small"
                    >
                        Close
                    </McButton>
                    <McButton
                        slot="primaryAction"
                        variant="primary"
                        type="submit"
                        label="Confirm"
                        data-testid="confirm"
                        fit="small"
                    ></McButton>
                </McModal>
            </form>
        </>
    )
}

export default ManageWriteAccessRequestsModal