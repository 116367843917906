import { McModal, McButton, McIcon, McCheckbox } from "@maersk-global/mds-react-wrapper";
import { McTab, McTabBar } from "@maersk-global/mds-react-wrapper";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

interface IPROPS {
    isModalOpen?: boolean;
    onCloseFunc?: any;
    infoData?: any;
    assignmentDetails?: any;
    individualAccessRequestsData?: any;
    requestWriteAccessModal?: any;
    userRole?: any;
    setManageAccessEditedDetails?: any;
    charterRequestId?: any;
    indicationRequestId?: any;
}

const ManageAccessModal = ({ isModalOpen, onCloseFunc, infoData, assignmentDetails, individualAccessRequestsData, requestWriteAccessModal, userRole, setManageAccessEditedDetails, charterRequestId, indicationRequestId }: IPROPS) => {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm();
    const [tab, setTab] = useState(0);
    const [accessRequestsData, setAccessRequestsData] = useState<any>();
    const [details, setDetails] = useState([]);
    const commentValue = infoData?.comment;
    const closeFilter = () => {
        onCloseFunc();
    }

    const changeHandler = (item: any) => {
        setTab(item.detail);
        if (item.detail === 1) {
            // getAccessRequestsData();
        }
    }

    const accessType = [
        { value: "Write", label: "Write" },
        { value: "Read", label: "Read" },
    ];
    const requestWriteAcess = () => {
        requestWriteAccessModal(true);
    }
    useEffect(() => {
        if (userRole == "VesselOwner" || userRole == "OwnerRepresentative") {
            setDetails(infoData?.vOR_AssignmentUserDtos);
        } else {
            setDetails(infoData?.cmrAssignmentUserDetails);
        }
    }, []);
    const onError = (errors, e) => console.log(errors, e);
    const chkValueChanged = (e: any, label: string) => { };
    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: 48,
            minHeight: 38,
        }),
    };
    const onSubmit = (data) => {
        if (userRole == "OwnerRepresentative") {
            const formattedData = {
                id: infoData?.id,
                indicationId: Number(indicationRequestId),
                comment: data.comments,
                vOR_AssignmentUserDtos: infoData?.vOR_AssignmentUserDtos?.map((rep, index) => ({
                    vorAssignmentUserId: rep?.vorAssignmentUserId
                        ? rep?.vorAssignmentUserId
                        : 0,
                    name: data[`name${index}`],
                    email: data[`email${index}`],
                    accessType: data[`accessType${index}`] ? data[`accessType${index}`] : rep[`accessType`],
                })),
            };
            setManageAccessEditedDetails(formattedData);
        } else {
            const formattedData = {
                charteringRequestId: Number(charterRequestId),
                assignmentId: infoData?.assignmentId,
                vesselIMO: infoData?.vesselIMO,
                openRange: infoData?.openRange,
                openPort: infoData?.openPort,
                comment: data?.comments,
                cmrAssignmentUserDetails: infoData?.cmrAssignmentUserDetails.map((rep, index) => ({
                    cmrAssignmentUserId: rep?.cmrAssignmentUserId
                        ? rep?.cmrAssignmentUserId
                        : 0,
                    name: data[`name${index}`],
                    email: data[`email${index}`],
                    accessType: data[`accessType${index}`] ? data[`accessType${index}`] : rep[`accessType`],
                })),
            }
            setManageAccessEditedDetails(formattedData);
        }

        onCloseFunc();
    }

    return (
        <>
            <form
                data-testid="form"
                className="charterMarketAddModal"
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <McModal
                    height="500px"
                    width="1000px"
                    className="broker-info-modal"
                    heading={`Manage Access`}
                    open={isModalOpen}
                    backdropcloseactiondisabled
                >
                    <div
                        className="closeIcon-modal"
                        data-testid="close"
                        onClick={closeFilter}
                    >
                        <McIcon icon="times"></McIcon>
                    </div>

                    <div className="tab-section">
                        <McTabBar
                            currentindex={tab}
                            change={changeHandler}
                            variant="default"
                        >
                            <McTab
                                className="custom-height"
                                slot="tab"
                                label="Add Representative"
                                data-testid="vessel-details-tab"
                            ></McTab>
                            <div className="tab-row tab-section" slot="panel">

                                <div className="broker-data">
                                    <div className="broker-data-io details-section">
                                        {userRole == "OwnerRepresentative" && infoData?.vOR_AssignmentUserDtos?.map((data: any, index: any) => {
                                            return (
                                                <div className="row details-row">
                                                    <div className="col-md-4">
                                                        <div className="header space-btw">Name</div>
                                                        <div className="value">
                                                            <Controller
                                                                name={`name${index}`}
                                                                control={control}
                                                                data-testid="name"
                                                                render={({ field: { onChange } }) => (
                                                                    <input
                                                                        className="inputText"
                                                                        {...register(`name${index}`)}
                                                                        type="text"
                                                                        role="name"
                                                                        onChange={(val) => {
                                                                            chkValueChanged(val, `name${index}`);
                                                                            onChange(val.target.value);
                                                                        }}
                                                                        defaultValue={data?.name}
                                                                    // readOnly={
                                                                    //     (isReadOnlyUser || rowAccessType === "Read") ? true : false
                                                                    //   }
                                                                    ></input>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="header">E-mail</div>
                                                        <div className="value">
                                                            <Controller
                                                                name={`email${index}`}
                                                                control={control}
                                                                data-testid="email"
                                                                render={({ field: { onChange } }) => (
                                                                    <input
                                                                        className="inputText"
                                                                        {...register(`email${index}`)}
                                                                        type="text"
                                                                        role="email"
                                                                        onChange={(val) => {
                                                                            chkValueChanged(val, `email${index}`);
                                                                            onChange(val.target.value);
                                                                        }}
                                                                        defaultValue={data?.email}
                                                                    // readOnly={
                                                                    //     (isReadOnlyUser || rowAccessType === "Read") ? true : false
                                                                    //   }
                                                                    ></input>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="header">Access type</div>
                                                        <div className="value">
                                                            {/* {data.accessType} */}
                                                            <Controller
                                                                control={control}
                                                                name={`accessType${index}`}
                                                                data-testid="accessType"
                                                                render={({ field: { onChange } }) => (
                                                                    <Select
                                                                        styles={customStyles}
                                                                        defaultValue={{
                                                                            value: data?.["accessType"],
                                                                            label: data?.["accessType"],
                                                                        }}
                                                                        options={accessType}
                                                                        name={`accessType${index}`}
                                                                        onChange={(val: any) => onChange(val.value)}
                                                                    // isDisabled={
                                                                    //     (isReadOnlyUser || rowAccessType === "Read") ? true : false
                                                                    // }
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {(userRole == "charteringManagerRepresentative" || userRole == "CharteringManager") && infoData?.cmrAssignmentUserDetails?.map((data: any, index: any) => {
                                            return (
                                                <div className="row details-row">
                                                    <div className="col-md-4">
                                                        <div className="header space-btw">Name</div>
                                                        <div className="value">
                                                            <Controller
                                                                name={`name${index}`}
                                                                control={control}
                                                                data-testid="name"
                                                                render={({ field: { onChange } }) => (
                                                                    <input
                                                                        className="inputText"
                                                                        {...register(`name${index}`)}
                                                                        type="text"
                                                                        role="name"
                                                                        onChange={(val) => {
                                                                            chkValueChanged(val, `name${index}`);
                                                                            onChange(val.target.value);
                                                                        }}
                                                                        defaultValue={data?.name}
                                                                    // readOnly={
                                                                    //     (isReadOnlyUser || rowAccessType === "Read") ? true : false
                                                                    //   }
                                                                    ></input>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="header">E-mail</div>
                                                        <div className="value">
                                                            <Controller
                                                                name={`email${index}`}
                                                                control={control}
                                                                data-testid="email"
                                                                render={({ field: { onChange } }) => (
                                                                    <input
                                                                        className="inputText"
                                                                        {...register(`email${index}`)}
                                                                        type="text"
                                                                        role="email"
                                                                        onChange={(val) => {
                                                                            chkValueChanged(val, `email${index}`);
                                                                            onChange(val.target.value);
                                                                        }}
                                                                        defaultValue={data?.email}
                                                                    // readOnly={
                                                                    //     (isReadOnlyUser || rowAccessType === "Read") ? true : false
                                                                    //   }
                                                                    ></input>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="header">Access type</div>
                                                        <div className="value">
                                                            {/* {data.accessType} */}
                                                            <Controller
                                                                control={control}
                                                                name={`accessType${index}`}
                                                                data-testid="accessType"
                                                                render={({ field: { onChange } }) => (
                                                                    <Select
                                                                        styles={customStyles}
                                                                        defaultValue={{
                                                                            value: data?.["accessType"],
                                                                            label: data?.["accessType"],
                                                                        }}
                                                                        options={accessType}
                                                                        name={`accessType${index}`}
                                                                        onChange={(val: any) => onChange(val.value)}
                                                                    // isDisabled={
                                                                    //     (isReadOnlyUser || rowAccessType === "Read") ? true : false
                                                                    // }
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    {/* {representatives.length < 5 && (
              <span className="add-more" onClick={addMoreFunc}>
                + Add more
              </span>
            )} */}


                                    <div className="broker-data-io details-section">
                                        <div className="col-md-11 comments-section">
                                            <label className="comment-label">Comments</label>
                                            <textarea
                                                data-testid="cm-comments"
                                                className="broker-details-commentArea"
                                                {...register("comments")}
                                                defaultValue={
                                                    commentValue !== null ? commentValue : ""
                                                }
                                                placeholder="comments"
                                                rows={3}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {individualAccessRequestsData?.length>0 && <McTab
                                slot="tab"
                                label="Access requests"
                                data-testid="commercial-details-tab"
                            ></McTab>}
                            <div className="tab-row" slot="panel">
                                <div className="broker-data">
                                    <div className="broker-data-io details-section">
                                        {individualAccessRequestsData?.map((data: any, index: any) => {
                                            return (
                                                <>
                                                    <div className="row details-row">
                                                        <div className="col-md-3">
                                                            <div className="header">Vessel</div>
                                                            <div className="value">{data.vesselName}</div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="header">Name</div>
                                                            <div className="value">{data.vesselName}</div>
                                                        </div>
                                                        <div className="col-md-4 input-space">
                                                            <div className="header">E-mail</div>
                                                            <div className="value">{data.createdBy}</div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="header">Access type</div>
                                                            <div className="value">
                                                                {data.vesselIMO}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row details-row">
                                                        <div className="col-md-3">
                                                            <div className="header">Open Port</div>
                                                            <div className="value">
                                                                {data.openPort}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="header">Open Range</div>
                                                            <div className="value">
                                                                {data.openRange}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Controller
                                                                name={`giveAccess${index}`}
                                                                control={control}
                                                                defaultValue=""
                                                                render={({ field: { onChange } }) => (
                                                                    <McCheckbox
                                                                        className="inputTextNew"
                                                                        name={`giveAccess${index}`}
                                                                        label="Give access"
                                                                        change={(e: any) => {
                                                                            onChange(e?.target?.checked);
                                                                        }}
                                                                        data-testid="giveAccess"
                                                                    ></McCheckbox>
                                                                )}
                                                            />
                                                        </div>

                                                    </div>
                                                </>
                                            )

                                        })}
                                    </div>
                                </div>
                            </div> */}
                        </McTabBar>
                    </div>
                    <McButton
                        slot="primaryAction"
                        variant="primary"
                        type="submit"
                        label="Confirm"
                        data-testid="confirm"
                        fit="small"
                    ></McButton>
                    <McButton
                        slot="secondaryAction"
                        variant="primary"
                        dialogAction="cancel"
                        onClick={onCloseFunc}
                        role="cancel-cp"
                        fit="small"
                    >
                        Close
                    </McButton>
                </McModal>
            </form>
        </>
    )
}

export default ManageAccessModal