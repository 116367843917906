import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { getAPI, postAPI, deleteAPI } from "../../../services/CepApiServices";
import moment from "moment";
import "../../IndicationRequestPages/IndicationRequestTable/IndicationRequestTable.scss";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import { MdsConstants } from "../../../utils/Constants";
import { StatusList } from "../../../utils/StatusList";
import { toast } from "react-toastify";
import { IndicationRequestModelVO } from "./IndicationRequestVOModel";
import { McButton, McIcon, McTooltip } from "@maersk-global/mds-react-wrapper";
import WriteAccessModal from "../../IndicationRequestPages/IndicationRequestTable/WriteAccessModal";
import AssignRepresentativeModal from "../../../CharterRequest/RequestPages/AssignRepresentativeModal/AssignRepresentativeModal";
import BrokerDetailsModal from "../../../CharterRequest/RequestPages/VesselsList/BrokerDetailsModal";
import EditVORDetailsModal from "../../../CharterRequest/RequestPages/EditVORDetailsModal/EditVORDetailsModal";
import RemoveRepresentativeModal from "../../../CharterRequest/RequestPages/RemoveRepresentativeModal/RemoveRepresentativeModal";
import { getUserRole, getUserName } from "../../../utils/token";
import RequestWriteAccessModal from "../../../CharterRequest/RequestPages/RequestWriteAccessModal/RequestWriteAccessModal";

const IndicationRequestTableVO = () => {
  const history = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [dDlOptions, setTableSizeDdl] = useState<any>([]);
  const [currentPageNum, setCurrentPageNum] = React.useState<number>(1);
  const [rejectPopUp, setRejectpopUp] = useState(false);
  const [hoverValue, setHoverValue] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [isWriteAccess, SetIsWriteAccess] = useState(false);
  const [rowData, setRowData] = useState<any>();
  const [idVal,setIDVal] = useState(0);
  const [vorDetailsModal, setIsVORDetailsModal] = useState(false);
  const [VOREditDetails, setVOREditDetails] = useState<any>();
  const [deleteVORModal,setIsDeleteVORModal] = useState(false);
  const [deleteRepId, setDeleteRepId] = useState(0);
  const [rowDetails,setRowDetails] = useState<any>();
  const [userRole, setUserRole] = useState<any>();
  const [isReadOnlyUser, setIsReadOnlyUser] = useState(false);
  const [userName, setUserName] = useState<any>("");
  const [rowAccessType, setRowAccessType] = useState<any>();
  const [requestWriteAccessSuccess, setRequestWriteAccessSuccess] = useState(false);
  const [isRequestWriteAccessModal, setIsRequestWriteAccessModal] = useState(false);
  const [assignmentId, setAssignmentId] = useState(0);
  let numOfRecords = 0;

  const onLoadDataFetch = async () => {
    setIsTableLoader(true);
    setTableSizeDdl(MdsConstants.ItemsPerPageDDl);

    const getAllVOIndicationRequest = await getAPI(
      `IndicationRequest/GetVesselOwnerIndicationRequest`
    );
    setTableData(getAllVOIndicationRequest);
    setIsTableLoader(false);
    numOfRecords = getAllVOIndicationRequest
      ? getAllVOIndicationRequest?.length
      : 0;
    setTotalPages(numOfRecords / 10);
  };

  const handleWriteAssigneeFun = (value: any) => {
    if(value?.vesselOwnerRepresentativeName == null) {
      SetIsWriteAccess(true); // Add modal
    } else {
      setRowDetails(value);
      setRowAccessType(value?.accessType);
      fetchBrokerDetails(value);
      setTimeout(() => {
        setIsVORDetailsModal(true); //Edit modal
      },1200);
      
    }
    setRowData(value);
    setIDVal(value.id);
  };
  const handleVORDetailsDisplay = (value: any) => {
    setIsVORDetailsModal(true);
  }
  const handleAssignee = (row: any) => {
    if(userRole == "VesselOwner") {
    if (
      row?.statusId != 4 &&
      row?.statusId != 3 &&
      row?.statusId != 7 &&
      row?.statusId !== 16 //Terminated requests
      // row?.accessType == "Write"
    ) {
      return (
        <span
          className="manage-access"
          onClick={() => handleWriteAssigneeFun(row)}
        >
          Manage Access
        </span>
      );
    } else {
      return "--";
    }
  } else {
    if (
      row?.statusId != 4 &&
      row?.statusId != 3 &&
      row?.statusId != 7 &&
      row?.statusId !== 16 
      // && row?.accessType == "Write"
    ) {
      return (
        <span
          className="manage-access"
          onClick={() => handleWriteAssigneeFun(row)}
        >
          Manage Access
        </span>
      );
    } else {
      return "--";
    }
  }
  };


  useEffect(() => {
    const getUserNameAsync = async () => {
      const UID = await getUserName();
      setUserName(UID);
    };
    getUserNameAsync();
  }, []);

  const closeFunc = () => {
    SetIsWriteAccess(false);
  };
  const handleLongText = (str: any) => {
    return str?.split(",").map((subStr: any) => {
      return (
        <div className="long-text">
          {subStr}
          <br />
        </div>
      );
    });
  };
  const accessTypeSetter = (accessType: any) => {
    if (accessType == "Read") {
      return (
        <span className="read-access">
          <McIcon icon="eye" className="read-access-icon"></McIcon>
          {accessType}
        </span>
      );
    } else if (accessType == "Write") {
      return (
        <span className="write-access">
          <McIcon icon="pencil" className="write-access-icon"></McIcon>
          {accessType}
        </span>
      );
    }
  };
  const columns: TableColumn<IndicationRequestModelVO>[] = [
    {
      name: "Status",
      selector: (row) => row.indicationStatus,
      cell: (row) =>
        StatusList(row.statusId, row.indicationStatus, row.comments, "VO"),
      sortable: true,
      width: "9%",
    },
    {
      name: "Vessel Name",
      selector: (row) =>
        row.vesselName !== null ? row.vesselName.toLowerCase() : "",
      cell: (row) => (row.vesselName !== null ? row.vesselName : ""),
      sortable: true,
      width: "12%",
    },
    {
      name: "IMO",
      selector: (row) => (row.imo !== null ? row.imo : ""),
      sortable: true,
      width: "6%",
    },
    {
      name: "Chartering Manager",
      selector: (row) =>
        row.charteringManagerName !== null ? row.charteringManagerName.toLowerCase() : "",
      cell: (row) => (row.charteringManagerName !== null ? row.charteringManagerName : ""),
      sortable: true,
      width: "12%",
    },
    {
      name: "Chartering Manager Representative",
      selector: (row) =>
        row.charteringManagerRepresentativeName !== null ? row.charteringManagerRepresentativeName.toLowerCase() : "",
      cell: (row) => (row.charteringManagerRepresentativeName !== null ? row.charteringManagerRepresentativeName : ""),
      sortable: true,
      width: "12%",
    },
    {
      name: "Vessel Owner",
      selector: (row) =>
        row.vesselOwnerName !== null ? row.vesselOwnerName.toLowerCase() : "",
      cell: (row) => (row.vesselOwnerName !== null ? row.vesselOwnerName : ""),
      sortable: true,
      width: "12%",
    },
    {
      name: "VO/Owner representative",
      selector: (row) =>
        row.vesselOwnerRepresentativeName !== null ? row.vesselOwnerRepresentativeName.toLowerCase() : "",
      sortable: true,
      width: "12%",
    },
    {
      name: "Created Date",
      selector: (row) => (row.createdOn !== null ? row.createdOn : ""),
      cell: (row) => moment(row.createdOn).format("DD-MMM-YYYY"),
      sortable: true,
      width: "8%",
    },
    {
      name: "Access Type",
      selector: (row) => (row.accessType !== null ? row.accessType : ""),
      cell: (row) => accessTypeSetter(row.accessType),
      sortable: true,
      width: "7%",
    },
    {
      name: "Access",
      cell: (row) => handleAssignee(row),
      width: "5%",
    },
  ];
  const tableRowClick = (row: any) => {
    if (row.isDirectNegotiation !== "No") return false;
    if (row.statusId == 2) {
      window.open(`/IndicationRequestPage?id=${row.id}`);
    } else if (row.statusId >= 5) {
      history(`/GetIndicationRequestDataAsyncVO?id=${row.id}`);
    } else if (row.statusId == 4) {
      setRejectpopUp(true);
    }
  };

  const getUserRoleAsync = async () => {
    const userRole = await getUserRole();
    setUserRole(userRole);
  };

  useEffect(() => {
    getUserRoleAsync();
    onLoadDataFetch();
  }, [pageLoad]);
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  const cursorPointerStyle = { cursor: "pointer" };
  const cursorDefaultStyle = { cursor: "default" };
  const conditionalRowStyles = [
    {
      when: (row) => row.isDirectNegotiation === "No",
      style: cursorPointerStyle,
    },
    {
      when: (row) => row.isDirectNegotiation !== "No"|| 
                     row.indicationStatus === "VO Rejected" || 
                     row.indicationStatus === "Indication Cancelled",
      style: cursorDefaultStyle,
    },
  ];

  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };

  const addRepresentatives = (formatteddata: any) => {
    assignCMRtoVsl(formatteddata,'assigned');
  }

  const assignCMRtoVsl = async (obj: any,type:any): Promise<void> => {
    const payload = obj;
    const status = await postAPI(`IndicationRequest/SaveVORssignment`, payload);
    if (status.StatusCode == 400 || status.message) {
      setTimeout(() => {
        notify("Failed to add representative", false)
      }, 1000);
      SetIsWriteAccess(false);
    }
    else {
      setTimeout(() => {
        notify(`Representatives ${type} successfully.`, true)
      }, 1000);
      SetIsWriteAccess(false);
      onLoadDataFetch();
    }
  };
  const checkReadOnlyRolesVO = (details) => {
    const readOnlyEmails = details
    .filter((user) => user?.accessType === "Read")
    .map((user) => user?.email);
  const checkUserEMail = readOnlyEmails.includes(userName.email);
  setIsReadOnlyUser(checkUserEMail);
  }
  const fetchBrokerDetails = async (data) => {
    try {
      let response: any;
      response = await getAPI(
        `IndicationRequest/GetVORAssignment?IndicationId=` +
        data.id 
      );
      setVOREditDetails(response);
      setAssignmentId(response?.id);
      checkReadOnlyRolesVO(response?.vOR_AssignmentUserDtos);
    } catch (error) {
      console.log("error", error);
    }
  };

  const setEditedVORDetails = (data) => {
    assignCMRtoVsl(data,'edited');
  }

  const setDeletedRepresentativeData = (repData: any) => {
    setDeleteRepId(repData);
    setIsVORDetailsModal(false);
    setIsDeleteVORModal(true);
  }

  const onSubmitDelete = (id: any) => {
    deletedRepresentative(id);
  }

  const deletedRepresentative = async (id: any): Promise<void> => {
    // const payload = obj;
    const status = await deleteAPI(`IndicationRequest/DeleteVORAssignment?Id=` + id);
    if (status?.StatusCode == 400 || status?.message) {
      setTimeout(() => {
        notify("Failed to delete representative", false)
      }, 1000);
      setIsDeleteVORModal(false);
      setIsVORDetailsModal(false);
    }
    else {
      setTimeout(() => {
        notify("Representatives deleted successfully.", true)
      }, 1000);
      setIsDeleteVORModal(false);
      fetchBrokerDetails(rowDetails);
      setTimeout(() => {
        setIsVORDetailsModal(true);
      }, 1500);
    }
  };

  const getRequestWriteAccess = async()=> {
    const response = await getAPI(
      `IndicationRequest/RequestVORWriteAccess?VORAssignmentId=` + assignmentId
    );
    if(response) {
      setRequestWriteAccessSuccess(true);
    }
  }

  const requestWriteAccess = (val:any)=> {
    setIsRequestWriteAccessModal(val);
  }

  const closeRequestWriteAccessModal = (type:any)=> {
    if(type=="submit") {
      getRequestWriteAccess();
    }
    setIsRequestWriteAccessModal(false);
    setVOREditDetails(true);
  }

  return (
    <div className="ir-table-main table-margin2 bg-white">
      <DataTable
        columns={columns}
        data={tableData}
        progressPending={isTableLoader}
        onRowClicked={tableRowClick}
        progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        conditionalRowStyles={conditionalRowStyles}
      />
      {/* <WriteAccessModal
        isModalOpen={isWriteAccess}
        onCloseFunc={closeFunc}
        rowData={rowData}
        isCm={false}
        reloadPage={onLoadDataFetch}
      /> */}
      {/* <AssignRepresentativeModal/> */}
      <AssignRepresentativeModal
        isModalOpen={isWriteAccess}
        infoData={addRepresentatives}
        onLoadData={rowData}
        crId={idVal}
        isCloseModal={() => SetIsWriteAccess(false)}
        isFromIndicationReq = {true}
        userRole={userRole}
      ></AssignRepresentativeModal>
     {vorDetailsModal && <EditVORDetailsModal
        isModalOpen={vorDetailsModal}
        infoData={VOREditDetails}
        isCloseModal={() => setIsVORDetailsModal(false)}
        setEditedVORDetails={setEditedVORDetails}
        deletedRepresentative={setDeletedRepresentativeData}
        crId={idVal}
        userRole={userRole}
        isReadOnlyUser={isReadOnlyUser}
        requestWriteAccessModal={requestWriteAccess}
        requestWriteAccessSuccess={requestWriteAccessSuccess}
        rowAccessType={rowAccessType}
      ></EditVORDetailsModal>}
       {isRequestWriteAccessModal && (<RequestWriteAccessModal 
      onModalOpen={isRequestWriteAccessModal} 
      onModalClose={closeRequestWriteAccessModal} 
      />)}
       {deleteVORModal && (<RemoveRepresentativeModal
        onDeleteOpen={deleteVORModal}
        deleteRepId={deleteRepId}
        onSubmit={onSubmitDelete}
      ></RemoveRepresentativeModal>)}
    </div>
  );
};
export default IndicationRequestTableVO;
